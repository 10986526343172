// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deploy-button-container {
  flex: 1 1;
  display: flex; 
  padding: 1%;
  margin: 5%;
}

.instructions-and-actions-sidebar {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
}

.instructions-menu-header {
  flex: 2 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(20, 20, 20);
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 5px 5px 5px black;
  padding: 1%;
  margin: 1%;
  overflow-y: auto;
  height: 100%;
  text-align: center;
}

.instructions-menu-list-container {
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: gray;
  border: 5px solid black;
  flex: 19 1;
  height: 100%;
  margin: 1%;
  overflow-y: auto;
  padding: 1%;
}

.instructions-menu-list-item {
  border: 1px solid black;
  margin: 1px;
}

.instructions-menu-list-item-button {
  color: white;
  font-size: 0.75em !important;
  text-shadow: 1px 1px 1px gray;
}`, "",{"version":3,"sources":["webpack://./src/styles/InstructionsAndActionsSideBar.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,SAAO;EACP,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,iCAAiC;EACjC,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;EAC7B,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,UAAQ;EACR,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,6BAA6B;AAC/B","sourcesContent":[".deploy-button-container {\n  flex: 1;\n  display: flex; \n  padding: 1%;\n  margin: 5%;\n}\n\n.instructions-and-actions-sidebar {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 100%;\n}\n\n.instructions-menu-header {\n  flex: 2;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  background-color: rgb(20, 20, 20);\n  border: 1px solid black;\n  border-radius: 10px;\n  box-shadow: 5px 5px 5px black;\n  padding: 1%;\n  margin: 1%;\n  overflow-y: auto;\n  height: 100%;\n  text-align: center;\n}\n\n.instructions-menu-list-container {\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  background-color: gray;\n  border: 5px solid black;\n  flex: 19;\n  height: 100%;\n  margin: 1%;\n  overflow-y: auto;\n  padding: 1%;\n}\n\n.instructions-menu-list-item {\n  border: 1px solid black;\n  margin: 1px;\n}\n\n.instructions-menu-list-item-button {\n  color: white;\n  font-size: 0.75em !important;\n  text-shadow: 1px 1px 1px gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
