// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-container {
  align-items: center;
  background-color: #282a36;
  border: 2px solid black;
  border-radius: 10px;
  color: white;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 95%;
  width: 10%;
  font-size: 0.8em;
  overflow: auto;
}

.code-container-div {
  display: flex;
  flex: 1 1;
  height: 100%;
  margin: 20px;
  width: 10%;
}`, "",{"version":3,"sources":["webpack://./src/styles/CodeContainer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,YAAY;EACZ,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".code-container {\n  align-items: center;\n  background-color: #282a36;\n  border: 2px solid black;\n  border-radius: 10px;\n  color: white;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 95%;\n  width: 10%;\n  font-size: 0.8em;\n  overflow: auto;\n}\n\n.code-container-div {\n  display: flex;\n  flex: 1;\n  height: 100%;\n  margin: 20px;\n  width: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
