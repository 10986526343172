// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contract-container-container {
  align-items: center;
  background-color: rgb(39 207 230);
  display: flex;
  flex-direction: row;
  flex: 20 1;
  justify-content: center;
  max-height: 100%;
  height: 100px;
}

.contract-container-box {
  align-items: center;
  background-color: white;
  border: 2px solid gray;
  border-radius: 10px;
  color: black;
  flex: 2 1;
  height: 95%;
  width: 100%;
  margin-top: 3%;
  margin-left: 1%;
  margin-bottom: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ContractContainer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iCAAiC;EACjC,aAAa;EACb,mBAAmB;EACnB,UAAQ;EACR,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,SAAO;EACP,WAAW;EACX,WAAW;EACX,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".contract-container-container {\n  align-items: center;\n  background-color: rgb(39 207 230);\n  display: flex;\n  flex-direction: row;\n  flex: 20;\n  justify-content: center;\n  max-height: 100%;\n  height: 100px;\n}\n\n.contract-container-box {\n  align-items: center;\n  background-color: white;\n  border: 2px solid gray;\n  border-radius: 10px;\n  color: black;\n  flex: 2;\n  height: 95%;\n  width: 100%;\n  margin-top: 3%;\n  margin-left: 1%;\n  margin-bottom: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
