// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instruction-node-assign {
  background-color: white;
  margin: 2px;
  padding: 2px;
  text-align: left;
  font-size: 0.5em;
  border: black 1px dashed;
}

.instruction-node-content {
  align-items: center;
  align-content: center;
  font-size: 0.5em;
  text-align: center;
  text-shadow: 1px 1px 1px gray;
  width: 200px;
}

.instruction-node-input {
  background-color: white;
  margin: 2px;
  padding: 2px;
  text-align: left;
  font-size: 0.5em;
  border: black 1px dotted;
}


.instruction-node-input-to-instruction {
  background-color: white;
  /* margin: 2px; */
  padding: 2px;
  text-align: left;
  font-size: 0.5em;
  border: black 1px solid;
  text-align: left;
  overflow-x: auto;
  width: 100%;

}`, "",{"version":3,"sources":["webpack://./src/styles/InstructionNode.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;AAC1B;;;AAGA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;;AAEb","sourcesContent":[".instruction-node-assign {\n  background-color: white;\n  margin: 2px;\n  padding: 2px;\n  text-align: left;\n  font-size: 0.5em;\n  border: black 1px dashed;\n}\n\n.instruction-node-content {\n  align-items: center;\n  align-content: center;\n  font-size: 0.5em;\n  text-align: center;\n  text-shadow: 1px 1px 1px gray;\n  width: 200px;\n}\n\n.instruction-node-input {\n  background-color: white;\n  margin: 2px;\n  padding: 2px;\n  text-align: left;\n  font-size: 0.5em;\n  border: black 1px dotted;\n}\n\n\n.instruction-node-input-to-instruction {\n  background-color: white;\n  /* margin: 2px; */\n  padding: 2px;\n  text-align: left;\n  font-size: 0.5em;\n  border: black 1px solid;\n  text-align: left;\n  overflow-x: auto;\n  width: 100%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
