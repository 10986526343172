// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-header {
  flex: 1 1;
  text-align: left;
  font-size: 0.8em;
  margin: 1em;
}

.info-header-dependency-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/styles/InfoHeader.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".info-header {\n  flex: 1;\n  text-align: left;\n  font-size: 0.8em;\n  margin: 1em;\n}\n\n.info-header-dependency-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
