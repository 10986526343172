// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contract-header {
  align-content: center;
  align-items: center;
  background-color: rgb(39 190 230);
  border-radius: 10px;
  box-shadow: 5px 5px 5px black;
  display: flex;
  flex: 5 1;
  flex-direction: row;
  font-size: 1em;
  justify-content: center;
  margin: 0em 2em;
  padding: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ContractHeader.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,iCAAiC;EACjC,mBAAmB;EACnB,6BAA6B;EAC7B,aAAa;EACb,SAAO;EACP,mBAAmB;EACnB,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,YAAY;AACd","sourcesContent":[".contract-header {\n  align-content: center;\n  align-items: center;\n  background-color: rgb(39 190 230);\n  border-radius: 10px;\n  box-shadow: 5px 5px 5px black;\n  display: flex;\n  flex: 5;\n  flex-direction: row;\n  font-size: 1em;\n  justify-content: center;\n  margin: 0em 2em;\n  padding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
