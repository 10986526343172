// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-container {
  align-content: center;
  align-items: center;
  background-color: gray;
  box-shadow: 5px 5px 5px black;
  display: flex;
  flex: 1 1;
  height: 50%;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/styles/FileUpload.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,sBAAsB;EACtB,6BAA6B;EAC7B,aAAa;EACb,SAAO;EACP,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".file-upload-container {\n  align-content: center;\n  align-items: center;\n  background-color: gray;\n  box-shadow: 5px 5px 5px black;\n  display: flex;\n  flex: 1;\n  height: 50%;\n  justify-content: center;\n  margin: 10px;\n  padding: 10px;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
